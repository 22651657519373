var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"myProfileSection changePwdSection"},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"passwordForm"},[_c('form',{ref:"form"},[_c('b-col',{staticClass:"p-0 d-block float-left pl-2",attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"current password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Current Password*"}},[_c('b-form-input',{attrs:{"placeholder":"Enter current password","autocomplete":"off","type":"password","required":""},on:{"blur":function($event){_vm.currentpassword = _vm.currentpassword.trim()}},model:{value:(_vm.currentpassword),callback:function ($$v) {_vm.currentpassword=$$v},expression:"currentpassword"}}),(_vm.currentPasswordError != '')?_c('small',{staticClass:"text-danger",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.currentPasswordError))]):_c('small',{staticClass:"text-danger",staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"p-0 d-block float-left pl-2",attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"new password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"New Password*"}},[_c('b-form-input',{attrs:{"placeholder":"Enter new password","type":"password","autocomplete":"off","required":""},on:{"blur":function($event){_vm.newpassword = _vm.newpassword.trim()},"keyup":function($event){return _vm.isValidPassword($event, 'password')}},model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}}),_c('small',{staticClass:"text-danger",staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))]),(_vm.passwordErrors != '' && errors[0] == '')?_c('small',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.passwordErrors))]):_vm._e()],1)]}}])})],1),_c('b-col',{staticClass:"p-0 d-block float-left pl-2",attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"confirm new password","rules":"required|confirmed:new password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Confirm Password*"}},[_c('b-form-input',{attrs:{"placeholder":"Re-enter new password","type":"password","autocomplete":"off","required":""},on:{"blur":function($event){_vm.confirmnewpassword = _vm.confirmnewpassword.trim()},"keyup":function($event){return _vm.isValidPassword($event, 'confirmpassword')}},model:{value:(_vm.confirmnewpassword),callback:function ($$v) {_vm.confirmnewpassword=$$v},expression:"confirmnewpassword"}}),(
                      _vm.confirmPasswordErrors != '' && _vm.confirmnewpassword != ''
                    )?_c('small',{staticClass:"text-danger InvalidInput",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.confirmPasswordErrors))]):(_vm.confirmPasswordErrors == '')?_c('small',{staticClass:"text-danger InvalidInput",staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] == "The confirm new password field confirmation does not match" ? "The new password and confirm password does not match" : errors[0]))]):_c('small',{staticClass:"text-danger InvalidInput",staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"p-0 d-block float-right pl-2 btnWrapper",staticStyle:{"text-align":"right"},attrs:{"md":"12"}},[_c('b-form-group',[_c('b-button',{staticClass:"mr-1",staticStyle:{"cursor":"pointer !important"},attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.cancelChanges()}}},[_vm._v("CANCEL")]),_c('b-button',{staticStyle:{"margin-right":"10px","cursor":"pointer !important"},attrs:{"variant":"primary","disabled":_vm.showSpinner},on:{"click":function($event){return _vm.savePassword()}}},[_vm._v("SAVE")])],1)],1),(_vm.showSpinner)?_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"md":"12"}},[(_vm.showSpinner)?_c('b-spinner',{staticStyle:{"width":"50px","height":"50px"}}):_vm._e()],1):_vm._e()],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }