<template>
  <div class="myProfileSection changePwdSection">
    <b-card>
      <b-row>
        <b-col md="12">
          <validation-observer ref="passwordForm">
            <form ref="form">
              <b-col md="4" class="p-0 d-block float-left pl-2">
                <validation-provider
                  #default="{ errors }"
                  name="current password"
                  rules="required"
                >
                  <b-form-group label="Current Password*">
                    <b-form-input
                      placeholder="Enter current password"
                      v-model="currentpassword"
                      @blur="currentpassword = currentpassword.trim()"
                      autocomplete="off"
                      type="password"
                      required
                    />
                    <small
                      style="color: red"
                      v-if="currentPasswordError != ''"
                      class="text-danger"
                      >{{ currentPasswordError }}</small
                    >
                    <small v-else class="text-danger" style="display: block">{{
                      errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="p-0 d-block float-left pl-2">
                <validation-provider
                  #default="{ errors }"
                  name="new password"
                  rules="required"
                >
                  <b-form-group label="New Password*">
                    <b-form-input
                      placeholder="Enter new password"
                      v-model="newpassword"
                      @blur="newpassword = newpassword.trim()"
                      @keyup="isValidPassword($event, 'password')"
                      type="password"
                      autocomplete="off"
                      required
                    />
                    <small class="text-danger" style="display: block">{{
                      errors[0]
                    }}</small>
                    <small style="color: red" v-if="passwordErrors != '' && errors[0] == ''">{{
                      passwordErrors
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="p-0 d-block float-left pl-2">
                <validation-provider
                  #default="{ errors }"
                  name="confirm new password"
                  rules="required|confirmed:new password"
                >
                  <b-form-group label="Confirm Password*">
                    <b-form-input
                      placeholder="Re-enter new password"
                      v-model="confirmnewpassword"
                      @blur="confirmnewpassword = confirmnewpassword.trim()"
                      @keyup="isValidPassword($event, 'confirmpassword')"
                      type="password"
                      autocomplete="off"
                      required
                    />
                    <small class="text-danger InvalidInput"
                      v-if="
                        confirmPasswordErrors != '' && confirmnewpassword != ''
                      "
                      style="color: red"
                      >{{ confirmPasswordErrors }}</small
                    >
                    <small
                      v-else-if="confirmPasswordErrors == ''"
                      style="color: red"
                      class="text-danger InvalidInput"
                      >{{
                        errors[0] ==
                        "The confirm new password field confirmation does not match"
                          ? "The new password and confirm password does not match"
                          : errors[0]
                      }}</small
                    >
                    <small v-else class="text-danger InvalidInput" style="display: block">{{
                      errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12" style="text-align: right" class="p-0 d-block float-right pl-2 btnWrapper">
                <b-form-group>
                  <b-button
                    style="cursor: pointer !important"
                    variant="outline-secondary"
                    class="mr-1"
                    @click="cancelChanges()"
                    >CANCEL</b-button
                  >
                  <b-button
                    style="margin-right: 10px; cursor: pointer !important"
                    variant="primary"
                    @click="savePassword()"
                    :disabled="showSpinner"
                    >SAVE</b-button
                  >
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                v-if="showSpinner"
                class="d-flex justify-content-center"
              >
                <b-spinner v-if="showSpinner" style="width: 50px; height: 50px" />
              </b-col>
            </form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import firebase from "@/utils/firebaseInit";
 import firebaseNew from "firebase"
import axios from "axios";
// import { getAuth, reauthenticateWithCredential } from "firebase/auth";
// const auth = getAuth();
export default {
  name: "change-password",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
    ValidationProvider,ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      showSpinner: false,
      confirmnewpassword: "",
      newpassword: "",
      currentpassword: "",
      passwordErrors: "",
      confirmPasswordErrors: "",
      userId: "",
      currentPasswordError: "",
    };
  },
  created() {
    var self = this;
    self.showSpinner = true;
    self.localObject = {};
    self.localObject = JSON.parse(localStorage.getItem("currentUser"));
    self.userId = self.localObject.id;
    self.showSpinner = false;
  },
  methods: {
    savePassword() {
      var self = this;
      const user = firebase.auth().currentUser;
      self.showSpinner = true;
      self.currentPasswordError = "";
      // console.log(self.localObject,'self.localObject');
      self.$refs.passwordForm.validate().then((valid) => {
        if (
          valid &&
          self.passwordErrors == "" &&
          self.confirmPasswordErrors == "" &&
          self.currentPasswordError == ""
        ) {
          const credential = firebaseNew.auth.EmailAuthProvider.credential(
            self.localObject.email,
            self.currentpassword
          );
          user
            .reauthenticateWithCredential(credential)
            .then(() => {
              // axios
              //   .post("http://localhost:4000/api/v1/setNewPassword", {
                axios.post(process.env.VUE_APP_APIURL + '/api/v1/setNewPassword', {
                  uid: self.userId,
                  newPassword: self.newpassword,
                })
                .then((apiRes) => {
                  // console.log(apiRes,'apiRes');
                  if (apiRes.data.status) {
                    self.$toast.success("Password updated successfully");
                    self.localObject.password = self.newpassword
                    localStorage.setItem("currentUser",JSON.stringify(self.localObject));
                    self.showSpinner = false;
                    self.cancelChanges();
                    // self.$router.push({name:'auth-login'});
                  }else{
                    self.showSpinner = false;
                    self.$toast.error('Something went to wrong');
                  }
                })
                .catch((error) => {
                    self.$toast.error("Something went to wrong", error);
                    self.showSpinner = false;
                });
            })
            .catch((error) => {
              if (error.code == "auth/wrong-password") {
                self.currentPasswordError = "Current password is invalid.";
                this.showSpinner = false;
              } else if (error.code == "auth/too-many-requests") {
                self.currentPasswordError =
                  "Made multiple attempts to change password try after some time.";
                this.showSpinner = false;
              } else {
                self.confirmnewpassword = "";
                self.newpassword = "";
                self.currentpassword = "";
                this.showSpinner = false;
                console.error(error);
              }
            });
        }else{
            self.showSpinner = false;
        }
      });
    },
    cancelChanges() {
      var self = this;
      self.showSpinner = false;
      self.confirmnewpassword = "";
      self.newpassword = "";
      self.currentpassword = "";
      (self.passwordErrors = ""), (self.confirmPasswordErrors = "");
      self.currentPasswordError = "";
      self.$refs.passwordForm.reset();
    },
    isValidPassword(event, from) {
      var self = this;
      var reg =
        /^([a-z]*)(?=.*[A-Z])([0-9]*)(?=.{8,})(?=.*[!#$%&'*+/=?^_`{|}~@])/;
      if (reg.test(event.target.value)) {
        self.passwordErrors = "";
        self.confirmPasswordErrors = "";
      } else {
        if (from == "password") {
          self.passwordErrors =
            "Password must have at least 8 characters and contain at least one uppercase letter and one special character";
        } else {
          self.confirmPasswordErrors =
            "Confirm password must have at least 8 characters and contain at least one uppercase letter and one special character";
        }
      }
    },
  },
};
</script>